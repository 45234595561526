<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        Employment Legislation
      </h2>
      <img
        src="../../assets/img/tools_legislation2.jpg"
        alt="Legislation Banner"
        class="w-full lg:h-96 h-80 mt-4 object-cover"
      >
      <br>
      <br>
      <div>
        Companies need to be constantly aware of the latest
        employment legistions to make adjustments on time in order to
        abid by the local law and at the same time remain cost-effective.
        AIMS Employment Legislation provides detailed summary of the
        most up-to-date legislations across over 180 countries at one glance.
      </div>
        <br>
        <br>
      <div class="flex flex-wrap justify-center lg:gap-16 gap-10">
        <img
          src="../../assets/img/tools_legislation1.png"
          alt="Expatriate Practice Banner"
          class="w-full"
        >
        <!-- <img
          src="../../assets/img/tools_legislation3.png"
          alt="Expatriate Practice Banner"
          class="w-full"
        > -->
      </div>
        <br>
        <br>
        <div class="mx-auto">
         Key Aspects of Employment Legislation:
          <ul class="px-10 p-4 list-disc text-red-700">
            <li>Annual Leave</li>
            <li>Medical Leave</li>
            <li>Maternity Leave</li>
            <li>Paternity Leave</li>
            <li>Childcare Leave</li>
            <li>Compassionate Leave</li>
            <li>Marriage Leave</li>
            <li>Working Hours</li>
            <li>Hiring</li>
            <li>Redundancy</li>
            <li>Equality</li>
            <li>Unemployment</li>
            <li>Retirement</li>
            <li>Social Security</li>
          </ul>
        </div>
      <br>
      <br>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
